import {useRef, useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import * as AxiosLogger from 'axios-logger';
import { SyncLoader } from "react-spinners";
import ImageResize from "../ImageResizer";
import Webcam from "react-webcam";

const instance = axios.create();
instance.interceptors.request.use(AxiosLogger.requestLogger);

const imageMimeType = /image\/(png|jpg|jpeg)/i;

export const ScanButton = (props) => {
  

  //is set with the prediction of the model
  const [predictions, setPredictions] = useState(null);
  //state for online model, is set when image is saved to server
  const [imageSaved, setimageSaved] = useState(null);

  //States for image resize
  const [imageToResize, setImageToResize] = useState(undefined);
  const [resizedImage, setResizedImage] = useState(null);
  //resizedImage is String, but we need an File Object - therefore we need these states
  const [resizedImageFile, setResizedImageFile] = useState(null);

  const dataLoaded = useRef(false);
  const navigate = useNavigate();
  //states for the spinner
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#fae03c");

  const [errorMessage, setErrorMessage] = useState('');


  const [showWebcam, setWebcam] = useState(null);
  const [flashStatus, setFlashStatus] = useState(false);

  function errorBtnHandler() {
    setErrorMessage('');
  }

  //css for the spinner
  const override: CSSProperties = {
    margin: "0 auto",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  };


  //open file input and set dataLoaded 
  function callInput(){
    setWebcam("true");
  }


  //webcam

  const webcamRef = useRef();
  const size = useWindowSize();
  const isLandscape = size.height <= size.width;
  const ratio = isLandscape ? size.width / size.height : size.height / 
  size.width;

  // Hook
    function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
          // Set window width/height to state
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        }
        
        // Add event listener
        window.addEventListener("resize", handleResize);
        
        // Call handler right away so state gets updated with initial window size
        handleResize();
        
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
      }, []); // Empty array ensures that effect is only run on mount
    
      return windowSize;
    }

  function photoHandler () {
    //flash animation
    setFlashStatus(current => !current);
    showFlash(); 
  };

  function showFlash(){
    setTimeout(function() {
      setFlashStatus(current => !current);
      const imageSrc = webcamRef.current.getScreenshot();
      setResizedImage(imageSrc);
      dataLoaded.current = true;
      setWebcam('');
      setLoading(!loading);
    },145);
  };



  //OPTION 2 - ONLINE create image object from resized image


  useEffect(() => {
    if(resizedImage){    
      console.log("img to blob")
      let blob = dataURItoBlob(resizedImage);
      blob = blob.slice(0, blob.size, "image/jpeg")
      setResizedImageFile(blob);
    }

  },[resizedImage]);


  function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    //Old Code
    //write the ArrayBuffer to a blob, and you're done
    //var bb = new BlobBuilder();
    //bb.append(ab);
    //return bb.getBlob(mimeString);

    //New Code
    return new Blob([ab], );
}


  //OPTION 2a - ONLINE: take image and get predicitons from Cloud
  useEffect(() => {
    if(dataLoaded.current){
      console.log("Saving Image...");
      const formData = new FormData();
      console.log("image: "+resizedImageFile);
      formData.append("image", resizedImageFile);
        const response = axios({
          method: "post",
          url: "https://www.api.noizybutpurple.com/public/image-upload",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        }).then(function (response) {
          //handle success
          if(response.data.status == "success"){
            console.log("Success");
            console.log(response);
            setimageSaved(response.data.filePath)
          }else{
            console.log("Error Saving Image");
            if (response.data.error == "No file was sent!"){
              setErrorMessage({headline: "Uploading Error", error: "We are very sorry! While uploading your photo, it got lost. Please try again."});
              setLoading(!loading);
            }else  if (response.data.error == "img size to big"){
              setErrorMessage({headline: "Image size is too big", error: "That's a very nice photo, but it's too big. Please try again."});
              setLoading(!loading);
            } else  if (response.data.error == "img corrupted"){
              setErrorMessage({headline: "Image is corrupted", error: "An error occurred while uploading your brilliant photo. Please try again."});
              setLoading(!loading);
            } else  if (response.data.error == "wrong file type"){
              setErrorMessage({headline: "Wrong File Type", error: "Unfortunately, we can only analyze png, jpg and jpeg files. Please try again or change your camera settings."});
              setLoading(!loading);
            } 
            console.log(response);
          }
          
        }).catch(function (response) {
          //handle error
          console.log(response);
          setErrorMessage({headline: "Timeout", error: "We can't reach our servers at the moment. Please try again."});
          setLoading(!loading);
        });   
    }
  }, [resizedImageFile]);

  //call prediction api after image was saved to server
  useEffect(() => {
    if(imageSaved != null){
      console.log("Image saved");

      const response = axios({
        method: "post",
        url: "https://westeurope.api.cognitive.microsoft.com/customvision/v3.0/Prediction/589407ea-3a67-40de-bc52-f3e39aeba4ee/classify/iterations/Iteration9/url",
        data: {"url": imageSaved},
        headers: { "Content-Type": "application/json", "Prediction-Key": "d54bc8a835654c2583f634ed40aceae7" },
      }).then(function (response) {
        //handle success
        console.log(response);
  
        if(response.status == "200"){
          setPredictions(response.data.predictions)
        }else{
          setErrorMessage({headline: "Timeout", error: "We can't reach our AI servers at the moment. Please try again."});
          setLoading(!loading);
        }
       
      }).catch(function (response) {
        //handle error
        console.log(response);
        setErrorMessage({headline: "Timeout", error: "We can't reach our AI servers at the moment. Please try again."});
        setLoading(!loading);
      });  

    }
    
  },[imageSaved])

  
  //Option 2: ONLINE  when predictions are set, get top probability and open alien story
  useEffect(() => {
    if(predictions != null){
      let top1 = Array.from(predictions)
      .map(function(p, i) { // this is Array.map
        return {
          probability: p.probability,
          tagName: p.tagName // we are selecting the value from the obj
        };
      }).sort(function(a, b) {
        return b.probability - a.probability;
      }).slice(0, 1);//only return highest prop
      console.log(top1[0].probability);
      if(top1[0].probability>0.5){
        if(top1[0].tagName=="niels_house" || top1[0].tagName=="rinas_world"){
          setErrorMessage({headline: "This is an AR artwork", error: "To see the AR effects please use the Artivive app :) "});
          setLoading(!loading);
        }else{
          setLoading(!loading);
          navigate('/alien-stories/'+top1[0].tagName);
        }
        
      }else{
        setErrorMessage({headline: "Artwork not recognized", error: "Please try to take a picture of the whole artwork from the front as close as possible."});
        setLoading(!loading);
      }
    }
  }, [predictions]);


  return (
    <>
      <div className="btn" id={props.id} onClick={callInput}>{props.txt}</div>
      <ol id="prediction-list"></ol>
      <SyncLoader className="spinner" color={color} loading={loading} cssOverride={override} size={20} margin={4}/>
      {errorMessage && (
        <div id="overlay">
        <div id="error-message">
          <h3>{errorMessage.headline}</h3>
          <p>{errorMessage.error}</p>
          <div className="btn" id="error-btn" onClick={errorBtnHandler}>Ok</div>
        </div>
       </div>
      )}
      

      {     showWebcam &&
          <Webcam  screenshotQuality={0.7} height={size.height} width={size.width}  videoConstraints={{facingMode: 'environment', aspectRatio: ratio}} ref={webcamRef} audio={false}  screenshotFormat="image/jpeg" className="webcam-div"/>
      }

      {     showWebcam &&
        <>
        <div className='overlay-webcam'></div>
        <div className='photo-button' onClick={photoHandler}>
          <div className="circle"></div>
          <div className="ring"></div>
        </div>
        <div className={flashStatus ? 'on' : ''}id='flash'></div>
        </>
      }
       
    </>
  )
}
