import {
    BrowserRouter,
    Route,
    Routes
  } from "react-router-dom";
// import Helmet from 'react-helmet'; 

  
  import {Home} from "./Home";
  import {Contact} from "./Contact";
  import {Stories} from "./Stories";
  import {NotFoundPage} from "./404";
  
  export const Webpages = () => {

     return(
      <>

        <BrowserRouter>
          <Routes>
            <Route 
              exact path="/" element={<Home />}>
            </Route>
            <Route path="/contact" element={<Contact />}>
            </Route>
            <Route path="/alien-stories" element={<Stories />}>
            <Route path=":id" element={<Stories />} />
            </Route>
            <Route path="*"  element={<NotFoundPage />}>
            </Route>
          </Routes>
        </BrowserRouter>
       
      </>
    )
  }
  