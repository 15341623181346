export const ContentHeaderHome = () => {
  return (
    <div id="content-header">
        	<div id="profile-picture">
    </div>
    <div id="headlines">
      <h1 className="home-h1">&laquo;For me, interactive art is the future&raquo;</h1>
      <h2 className="home-h2">&ndash; noizybutpurple</h2>
    </div>
  </div>
  )
}
