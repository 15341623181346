import {useState, useEffect} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {Header} from "../components/layout/Header";
import {Navigation} from "../components/layout/Navigation";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { sanitize } from 'dompurify';
import '../assets/styles/story.scss';

export const Stories = () => {
  document.body.style.backgroundColor = "#fff";
  let params = useParams();
  
  const [story, setStory] = useState([]);
  const [audio, setAudio] = useState([]);
  const [nextAlien, setNextAlien] = useState([]);
  const [shop, setShop] = useState([]);

  useEffect(() => {
    axios
      .get(`/stories/${params.id}.json`)
      .then((res) => setStory(res.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (story.audio !== 0 && typeof story.audio !== "undefined"){
        setAudio(<div className="audio-player"><audio controls><source src={`../audio/${story.alien_name}.mp3`} type="audio/mpeg" />Your browser does not support the audio element.</audio></div>); 
    };

    if (typeof story.next_alien !== "undefined" && story.next_alien !== ""){
        setNextAlien(<div id="next-alien">To continue reading the story line scan alien <span id="next-alien-name">{story.next_alien}</span></div>);
    };

    if (typeof story.shop !== "undefined" && story.shop !== 0){
        const storyStyle = {
            backgroundImage: `url(../images/InteractiveArtworks_${story.alien_name}.jpg)`,
            backgroundImage: `url(../images/InteractiveArtworks_${story.alien_name}.webp)`,
        }
        setShop(<div id="story-end" style={storyStyle}><a rel="noopener noreferrer" target="_blank" className="btn" id="shop-btn" href="https://shop.noizybutpurple.com/interactive-portraits" >Shop Artwork</a></div>);
    };
    }, [story]);
 
  return (
    <>  
     <HelmetProvider>  
        <Helmet>
            <title>noizybutpurple app - stories</title>
        </Helmet>
        <Header newClass="story"/>
        <Navigation />
        <div id="story">
           <div id="story-header">
                <div id="story-header-image">
                    <picture>
                        <source type="image/webp" srcSet={`../images/${story.alien_name}_story_header.webp`} media="(min-width:340px)" />
                        <source type="image/png" srcSet={`../images/${story.alien_name}_story_header.jpg`} media="(min-width:340px)" />
                        <img src={`../images/${story.alien_name}_story_header.jpg`} alt={`of ${story.alien_name}`} />
                    </picture>
          </div>
            <div id="alien-meta">
              Meet {story.alien_name} - Alien {story.alien_number}
            </div>
            <div id="triangle"></div>
          </div>
          <div id="story-saying">
            <h1 dangerouslySetInnerHTML={{__html: sanitize(story.alien_saying)}}></h1>
          </div>
        <div id="story-section">  
            <div className="content">
                {audio}
                <div dangerouslySetInnerHTML={{__html: sanitize(story.alien_story)}} className="story-text">
                </div>
            </div>
        </div>
        {nextAlien}
        {shop}
        </div>
     </HelmetProvider>
    </>


  );
}
