// import Helmet from 'react-helmet';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {Header} from "../components/layout/Header";
import {Navigation} from "../components/layout/Navigation";
import {ContentHeaderHome} from "../components/layout/ContentHeaderHome";
// import {ScanButtonInput} from "../components/buttons/old.ScanImageButtonNativCameraInput";
// import {ScanButtonOffline} from "../components/buttons/offline.ScanImageButton";
import '../assets/styles/home.scss';
import { ScanButton } from '../components/buttons/ScanImageButton';
import PWAPrompt from 'react-ios-pwa-prompt'

export const Home = () => {
  document.body.style.backgroundColor = "rebeccapurple";
  return (
    <>  
     <HelmetProvider>  
        <Helmet>
            <title>noizybutpurple app</title>
        </Helmet>
        <Header/>
        <Navigation />
        <main>
            <ContentHeaderHome/>
            <div id="btns-home">
                {/* <ScanButton txt="Scan Artwork" id="scan-btn"/> */}
                <ScanButton txt="Scan Alien" id="scan-btn"/>
                {/* <ScanButtonOffline txt="Scan Artwork" id="scan-btn"/> */}
            </div>
            <PWAPrompt promptOnVisit={1} timesToShow={5} copyClosePrompt="Close" permanentlyHideOnDismiss={false}/>
        </main>
     </HelmetProvider>
    </>


  );
}
