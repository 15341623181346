import { Link } from "react-router-dom" 


export const Navigation = () => {

     return (
      <>
        <nav id="menu" aria-hidden="true" aria-labelledby="menu-toggle" role="navigation">
            <ul>
                <li><Link to="/" >Home</Link></li>
                <li><a href="https://www.noizybutpurple.com/swiss-art-expo" target="_blank" rel="noopener noreferrer">About the Project</a></li>
                <li><a href="https://shop.noizybutpurple.com/interactive-portraits" target="_blank" rel="noopener noreferrer">Buy Artworks</a></li>
                <li><Link to="/contact" >Contact</Link></li>
                <li><a href="https://www.noizybutpurple.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
            </ul>
            <div id="logo">
                <picture>
                    <source type="image/webp" srcSet="../images/logo_noizybutpurple.webp" media="(min-width:340px)" />
                    <source type="image/png" srcSet="../images/logo_noizybutpurple.png" media="(min-width:340px)" />
                    <img src="../images/logo_noizybutpurple.png" alt="logo_noizybutpurple" /> 
                </picture>
                <p>
                www.noizybutpurple.com
                </p>
            </div>
        </nav>
      </>
    )
  }
  