import {NavButton} from "../buttons/NavButton";

export const Header = (props) => {

    return (
        <header className={`banner ${props.newClass ? props.newClass : ""}`} id="banner">
            <NavButton />
        </header>
    )
  }
  