

export const NavButton = () => {

const toggleMenu = () => {
  var menu = document.getElementById("menu");
      var toggle = document.getElementById("menu-toggle");
      var line1 = document.getElementById("line-1");
      var line2 = document.getElementById("line-2");
      var line3 = document.getElementById("line-3");

      if (menu.getAttribute("aria-hidden") === "true") {
        menu.classList.add("open");
        menu.setAttribute("aria-hidden", "false");
        toggle.setAttribute("aria-expanded", "true");
        line1.classList.add("line-1-active");
        line2.classList.add("line-2-active");
        line3.classList.add("line-3-active");
      } else {
        menu.classList.remove("open");
        line1.classList.remove("line-1-active");
        line2.classList.remove("line-2-active");
        line3.classList.remove("line-3-active");
        menu.setAttribute("aria-hidden", "true");
        toggle.setAttribute("aria-expanded", "false");
      }

}

const toggleMenuOnKey = (e) => {
  var menu = document.getElementById("menu");
  var toggle = document.getElementById("menu-toggle");
  var line1 = document.getElementById("line-1");
  var line2 = document.getElementById("line-2");
  var line3 = document.getElementById("line-3");
  // 27 = ESC key
  if (e.keyCode === 27) {
    if (menu.getAttribute("aria-hidden") === 'false') {
      menu.classList.remove("open");
      line1.classList.remove("line-1-active");
      line2.classList.remove("line-2-active");
      line3.classList.remove("line-3-active");
      menu.setAttribute("aria-hidden", "true");
      toggle.setAttribute("aria-expanded", "false");
    }
  }

}

  return (
    <>
        <button onClick={toggleMenu} onKeyUp={toggleMenuOnKey} id="menu-toggle" aria-label="Show Menu" aria-expanded="false" aria-controls="menu">
              <span id="line-1" className="line line-1"></span>
              <span id="line-2" className="line line-2"></span>
              <span id="line-3" className="line line-3"></span>
        </button>
    </>
  )
}
