
import './assets/styles/reset.css';
import './App.scss';
import {Webpages} from "./views/Index";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import CookieConsent from "./components/layout/CookieConsent";


function App() {
  return (
    <HelmetProvider>
    <Helmet>
        <meta charset='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <meta http-equiv='X-UA-Compatible' content='IE=edge' />
        <meta name="author" content="noizybutpurple" />
        <meta property="og:title" content="noizybutpurple" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.app.noizybutpurple.com" />
        <meta property="og:site_name" content="noizybutpurple app " />
        <meta name="apple-mobile-web-app-title" content="noizybutpurple app" />
        <meta name="application-name" content="noizybutpurple app" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="msapplication-config" content="/assets/favicons/browserconfig.xml" />
        <meta name="theme-color" content="#ffffff" />
        <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png"/>
        <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png"/>
        <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png"/>
        <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png"/>
        <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png"/>
        <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png"/>
        <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png"/>
        <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png"/>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png"/>
        <link rel="icon" type="image/png" sizes="192x192" href="/android-icon-192x192.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
        <link rel="manifest" href="/manifest.json"/>
    </Helmet>
    <Webpages/>
    <CookieConsent />
    </HelmetProvider>
  );
}

export default App;
